import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/core/auth/_services/token-storage.service';
import { AcceptRejectNegotiation } from 'src/app/shared/models/accept-reject-negotiation';
import { DraftService } from 'src/app/shared/services/draft.service';
import { SharedService } from 'src/app/shared/services/shared.service';


@Component({
  selector: 'kt-reject-negotiation',
  templateUrl: './reject-negotiation.component.html',
  styleUrls: ['./reject-negotiation.component.scss']
})
export class RejectNegotiationComponent implements OnInit {

  constructor(public  dialogRef:  MatDialogRef<RejectNegotiationComponent>,
    @Inject(MAT_DIALOG_DATA) public  data:  any,
    private draftServive : DraftService,
    private toastr: ToastrService,
    public sharedService:SharedService,
    private router: Router,
    private tokenStorageService :TokenStorageService
    ) {
 }
 rejectionSteps: any = [
  {value: 9, name: 'Requster Modifications'},
  {value: 8, name: 'Financial Modifications'},
  

];
 form: AcceptRejectNegotiation = new AcceptRejectNegotiation();
 fileExtention;
 fileUrl;
  ngOnInit(): void {
    this.form.processTemplateReqRelId = this.data.processTemReqId;
    this.form.processId = this.data.processId;
    this.form.userMail = this.tokenStorageService.getUser().email;
    this.form.partyName = this.data.partyName;
    this.form.templateTypeName = this.data.documentTypeName;
  }
  onFileSelect(event): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop().toLowerCase();
  
      const allowedExtensions = ['pdf', 'doc', 'docx'];
      // Validate the file extension
      if (!allowedExtensions.includes(fileExtension)) {
        this.toastr.error('Invalid file type. Only PDF, DOC, and DOCX files are allowed.', 'Error!');
        this.form.file = null;
        return;
      }
  
      this.form.file = file;
      this.fileExtention = fileExtension;
  
      const reader = new FileReader();
      reader.readAsDataURL(file);
  
      reader.onload = (_event) => {
        this.fileUrl = reader.result;
      };
    }
  }
  confirm(){
    if(this.validateDocument() === true )
    {
      this.sharedService.sendClickEvent();
      this.draftServive.RejectNegotiation(this.form).subscribe(res =>{
      this.toastr.success("Negotiation confirmed successfully 😀" , "Success!");
      this.router.navigate(['negotiation/pending-negotiations']);
    },error=>{
      this.toastr.error("Failed to confirm Negotiation 😟" , "Error!");
    });
    this.dialogRef.close();
  }
  }
  validateDocument(){
    if(this.fileExtention != undefined && this.fileExtention === "docx" || "doc" ){
      return true;
    }
    else{
      this.toastr.error("file should be only word document 😲");
      return false;

    }
  }
}
