
 <div class="card">
    <div class="card-header">
      <h1>Replace Draft Document</h1>
    </div>
    <div class="card-body">


      <div style="color: red;font-size: 17px; text-align: center;" >
         
        Disclaimer : Please don't forget to upload the document including the initials of users.
        
      </div>

      <form class="form"  name="form" 
      (ngSubmit)="f.form.valid && confirm()"
      #f="ngForm"
      novalidate>
        <div class="form-group">
          <!-- <div class="row">
             
            <div class="col-xl-12 col-lg-12">
               <mat-form-field appearance="fill" >
             <mat-label>Alignment Type</mat-label>
              <mat-select name="alignment"  #alignment="ngModel" [(ngModel)]="form.alignment" required>
              <mat-option *ngFor="let align of alignType" [value]="align.value" >
            {{align.viewValue}}
            </mat-option>
            </mat-select>
            </mat-form-field>
        </div>
          </div> -->


          <div class="row">
            <div class="form-group col-12">
              <label for="comment">Comment</label>
              <textarea class="form-control" rows="3" name="comment"  #comment="ngModel" [(ngModel)]="form.comment" required></textarea>
              <div
              class="text-muted" style="width:100%"
              *ngIf="f.submitted && !form.comment">
              Comment is required!
              </div>
              </div>
          </div>
          <div class="row">
            <div class="form-group col-12">
              <label for="file">Attach File</label>
            <input type="file" class="form-control-file"
            name="file"
            accept=".pdf, .doc"
           required
            placeholder="upload document" (change)="onFileSelect($event)" required>
            <div
            class="text-muted" style="width:100%"
            *ngIf="f.submitted && !form.file">
             File is required!
            </div>
            </div>
          </div>

        </div>
<div>
  <button type="submit" class="btn btn-success btns">Upload</button>
  <button class="btn btn-danger btns btnsSide" (click)="dialogRef.close(false)">Cancel</button>
</div>
      </form>

    </div>

</div>


