<div class="card">
    <div class="card-header">
      <h1>Accept Negotation</h1>
    </div>
    <div class="card-body">

      <form class="form"  name="form" 
      (ngSubmit)="f.form.valid"
      #f="ngForm"
      novalidate>
        <div class="form-group">
          <div class="row">
             
            <div class="col-xl-12 col-lg-12">
               <mat-form-field appearance="fill" >
             <mat-label>Alignment Type</mat-label>
              <mat-select name="alignment"  #alignment="ngModel" [(ngModel)]="form.alignment" required>
              <mat-option *ngFor="let align of alignType" [value]="align.value" >
            {{align.viewValue}}
            </mat-option>
            </mat-select>
            </mat-form-field>
        </div>
          </div>


          <div class="row">
            <div class="form-group col-12">
              <label for="comment">Comment</label>
              <textarea class="form-control" 
              rows="3" 
              name="comment"  
              #comment="ngModel" 
              [(ngModel)]="form.comment"
              maxlength="300"
              ></textarea>
              <div class="text-muted" style="width:100%"
              *ngIf="form.comment?.length >= 300 && (comment.dirty || comment.touched)">
           Comment cannot exceed 300 characters.
         </div>
              <!-- <div
              class="text-muted" style="width:100%"
              *ngIf="f.submitted && !form.comment">
              Comment is required!
              </div> -->
              </div>
          </div>
          <div class="row">
            <div class="form-group col-12">
              <label for="file">Attach File</label>
            <input type="file" class="form-control-file"
            name="file"
            required
            placeholder="upload document"
            (change)="onFileSelect($event)"
            required
            accept=".pdf, .doc, .docx"
              >
            <div
            class="text-muted" style="width:100%"
            *ngIf="!form.file">
            Attached File is required!
            </div>
            </div>
          </div>

        </div>
<div>
  <button  (click)="confirmWithCommercial()" class="btn btn-primary btns confirmCommercial">Confirm With Requester</button>
  <button (click)="confirm()" class="btn btn-success btns btnsSide confirmCommercial">Proceed To Approval</button>
  <button class="btn btn-danger btns btnsSide" (click)="dialogRef.close(false)">Cancel</button>
</div>
      </form>

    </div>

</div>

